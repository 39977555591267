$input-height: 38px;

.color-picker-button {
    width: $input-height;
    height: $input-height;
    border-radius: 3px;
    background-color: red;
    margin-left: 2px;
    padding: 2px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.color-text-input {
    flex: 1;
}

.vehicle-dot-status {
    width: 20px;
    height: 20px;
    border-radius: 40px;
}


.add-product-form{
    min-width: 450px;
}

.error-message{
    color: red;
}

.active-geo-fence{
    background-color: #1E2E5C;
    *{
        color: white!important;
    }
}




// Template

.template-drawer {
    min-width: 375px;
}

// .template-subject {
//     max-width: 400px;
//     margin-bottom: 20px;
// }

.template-body {
    height: 100px;
}

// .template-type {
//     max-width: 400px;
//     margin-bottom: 20px;
// }